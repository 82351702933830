import React, {useState} from 'react'

const ClientsSection = ({ clients }) => {
    const [expanded, setExpanded] = useState(false)
    const SHELF_START = 0
    const SHELF_END = 6
    const CLIENT_START = 7
    const CLIENT_END = 24

    const renderClient = (client, index) => {
        return (
            <div key={index} className="column is-2 is-one-third-mobile">
                <div className="accordion-item">
                    <img className="blurb-image" src={client.image.publicURL || ''} alt={client?.title}/>
                </div>
            </div>
        )
    }

    return (
      <>
        <div className="block">
          <h2 className="title is-h2 has-text-centered" style={{ marginBottom: '1em' }}>{ clients.title }</h2>
        </div>

        <div className="block">
          <div className="columns is-multiline is-centered is-mobile is-vcentered">
            { clients.client.slice(SHELF_START, SHELF_END).map((client, index) => renderClient(client, index))}
            { !!expanded && clients.client.slice(CLIENT_START, CLIENT_END).map((client, index) => renderClient(client, index))}
          </div>
        </div>
        <div className="section is-small">
          <div className="block has-text-centered">
            <a className="button is-primary-invert" onClick={() => setExpanded(!expanded)}>
              Show {`${!expanded ? 'More' : 'Less'}`}
              <div className="icon ml-3">{`${!expanded ? '+' : '-'}`}</div>
            </a>
          </div>
        </div>
      </>
    )
}

export default ClientsSection