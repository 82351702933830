import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import { Helmet } from "react-helmet";
import ReactMarkdown from "react-markdown";

import { navigate } from "gatsby-link";
import CTAButton from "../components/CTAButton";
import ClientsSection from "../components/ClientsSection";
import SimpleHeader from "../components/SimpleHeader";
import HashtagHidden from "../components/HashtagHidden";

// eslint-disable-next-line
export const AboutPageTemplate = ({
    intro,
    openers,
    closing,
    founder,
    contactForm,
    clients
}) => {
  let state = {}
  const encode =(data) => {
    const request = Object.keys(data)
      .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
      return request
  }
  const handleChange = (e) => {
    state[e.target.name] = e.target.value
  }
  const handleSubmit = (e) => {
        e.preventDefault();
        const form = e.target;
        state.message = `${state.message}\n\nReferred by: ${state.referred}\nSize: ${state.size}`
        fetch("/", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: encode({
            "form-name": form.getAttribute("name"),
            ...state,
          }),
        })
          .then(() => navigate(form.getAttribute("action")))
          .catch((error) => alert(error));
      };

  return (
    <>
      <section className="section is-small no-padding-bottom">
        <div className="container">
          <Helmet>
            <meta charSet="utf-8" />
            <title>About Us - Compensation Consultants - Kamsa</title>
            <meta name="og:title" content="About Us - Kamsa" />
            <meta name="twitter:description" content="Get to know Kamsa, your partner for managing employee compensation. We combine technology with a human touch"/>
            <meta name="twitter:title" content="About Us - Kamsa" />
            <meta name="description" content="Get to know Kamsa, your partner for managing employee compensation. We combine technology with a human touch." />
          </Helmet>
        </div>
      </section>
      {/* INTRO START */}
      <section className="section section--gradient is-small">
        <div className="container">
          <SimpleHeader
            heading={intro.heading}
            header={intro.header}
            subheader={intro.subheader}
            buttonLink={intro.buttonLink}
            buttonText={intro.buttonText}
            isPageHeader
            columnSize={""}
          />
        </div>
      </section>
      {/* INTRO END */}
      {/* OPENERS START */}
      <section className="section is-small" style={{ marginBottom: '4rem' }}>
        <div className="container">
          <div className="columns is-multiline">
            <div className="column is-12 mb-6 has-text-centered">
              <div>
                <h2 className="title is-2 has-text-weight-bold">{openers.header}</h2>
              </div>
            </div>
            { openers.blurbs.map((elem, index) => {
              return (
                  <div key={index} className="column is-4 mb-6 has-text-centered">
                    <div className="block">
                      <img className="img-125" src={elem.image.publicURL} alt={elem.header} />
                    </div>
                    <div className="block">
                      <h1 className="title is-4">{elem.header}</h1>
                    </div>
                    <div className="block">
                      <p className="is-size-5">{elem.subheader}</p>
                    </div>
                  </div>
              )
            })}
          </div>
        </div>
      </section>
      {/* OPENERS END */}
      {/* CLOSING START */}
      <section className="section section--ivy-light is-medium">
        <div className="box" style={{ marginLeft: '3rem', marginRight: '3rem'}}>
          <div className="columns is-vcentered">
            <div className="column is-5">
              <div className="block">
                <img src={closing.image.publicURL} alt="closing" />
              </div>
            </div>
            <div className="column is-7">
              <div className="block">
                <h1 className="title is-2 has-text-weight-bold">{closing.header}</h1>
              </div>
              <div className="block pr-6">
                { closing.subheader.map((paragraph, index) => {
                  return (
                        <p className="subtitle is-size-5" key={index}>
                          { paragraph }
                        </p>
                  )
                })}
              </div>
              <div className="block">
                <CTAButton link={closing.buttonLink} text={closing.buttonText} />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* CLOSING END */}
      {/* FOUNDER START */}
      <section className="section is-small" style={{ paddingBottom: '6.25rem' }}>
        <div className="container px-6">
          <div className="block py-6 has-text-centered">
            <h2 className="subtitle is-2 has-text-weight-bold">{founder.header}</h2>
          </div>
          <div className="columns is-vcentered">
            <div className="column">
              {founder.description.map((paragraph, index) => {
                return (
                    <div className="block" key={index}>
                      <ReactMarkdown className="subtitle is-size-5">{paragraph}</ReactMarkdown>
                    </div>
                )})}
            </div>
            <div className="column">
              <div className="block has-text-centered">
                <img src={founder.image.publicURL} alt={"founder"} className="is-rounded-border" />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* FOUNDER END */}
      {/* CONTACTFORM START */}
      <section id="contact" className="section section--gray">
        <div className="box p-6">
          <div className="block has-text-centered mt-6">
            <h2 className="title is-2 has-text-weight-bold">Contact Us</h2>
          </div>
          <div className="columns">
            <div className="column is-3"/>
            <div className="column is-6">
              <div className="content">
                <form
                  name="contact"
                  method="post"
                  action="/about/thanks/"
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                  onSubmit={handleSubmit}
                >
                  <input type="hidden" name="form-name" value="contact" />
                  <div hidden>
                    <label>
                      Don’t fill this out:{" "}
                      <input name="bot-field" onChange={handleChange} />
                    </label>
                  </div>
                  <div className="field">
                    <label className="label" htmlFor={"name"}>
                      {contactForm.nameLabel}
                    </label>
                    <div className="control">
                      <input
                        className="input"
                        type={"text"}
                        name={"name"}
                        onChange={handleChange}
                        id={"name"}
                        required={true}
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label" htmlFor={"email"}>
                      {contactForm.emailLabel}
                    </label>
                    <div className="control">
                      <input
                        className="input"
                        type={"email"}
                        name={"email"}
                        onChange={handleChange}
                        id={"email"}
                        required={true}
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label" htmlFor={"size"}>
                      {contactForm.sizeLabel}
                    </label>
                    <div className="control">
                      <input
                        className="input"
                        type={"text"}
                        name={"size"}
                        onChange={handleChange}
                        id={"size"}
                        required={true}
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label" htmlFor={"referred"}>
                      {contactForm.referredLabel}
                    </label>
                    <div className="control">
                      <input
                        className="input"
                        type={"text"}
                        name={"referred"}
                        onChange={handleChange}
                        id={"referred"}
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label" htmlFor={"message"}>
                      {contactForm.messageLabel}
                    </label>
                    <div className="control">
                      <textarea
                        className="textarea"
                        name={"message"}
                        onChange={handleChange}
                        id={"message"}
                      />
                    </div>
                  </div>
                  <div className="field my-6 has-text-centered">
                    <button className="button is-medium is-link" type="submit" style={{ width: '35%' }}>
                      {contactForm.ctaLabel}
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="column is-3"/>
          </div>
        </div>
      </section>
      {/* CONTACTFORM END */}
      {/* CLIENTS START */}
      { !!clients.client ??
        <section className="section section--gray is-medium no-padding-bottom">
          <ClientsSection clients={clients} />
        </section>
      }
      {/* CLIENTS END */}
    </>
  );
};

AboutPageTemplate.propTypes = {
  intro: PropTypes.shape({
    heading: PropTypes.string,
    header: PropTypes.string,
    subheader: PropTypes.string,
    buttonLink: PropTypes.string,
    buttonText: PropTypes.string,
  }),
  openers: PropTypes.shape({
    header: PropTypes.string,
    blurbs: PropTypes.array,
  }),
  closing: PropTypes.shape({
    header: PropTypes.string,
    subheader: PropTypes.array,
    buttonLink: PropTypes.string,
    buttonText: PropTypes.string,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
  founder: PropTypes.shape({
    header: PropTypes.string,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    description: PropTypes.array
  }),
  contactForm: PropTypes.shape({
    nameLabel: PropTypes.string,
    emailLabel: PropTypes.string,
    sizeLabel: PropTypes.string,
    referredLabel: PropTypes.string,
    messageLabel: PropTypes.string,
    ctaLabel: PropTypes.string,
  }),
  clients: PropTypes.shape({
    title: PropTypes.string,
    client: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        }),
    )
  })
};

const AboutPage = ({ data, pageContext }) => {
  const { frontmatter } = data.markdownRemark;
  const { clients } = pageContext
  clients.title = frontmatter.aboutPageClients.title

  return (
    <Layout>
      <AboutPageTemplate
        intro={frontmatter.aboutPageIntro}
        openers={frontmatter.aboutPageOpeners}
        closing={frontmatter.aboutPageClosing}
        founder={frontmatter.aboutPageFounder}
        contactForm={frontmatter.aboutPageContactForm}
        clients={clients}
      />
      <HashtagHidden hashtags={frontmatter.aboutPageHashtags} />
    </Layout>
  );
};

AboutPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default AboutPage;

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        aboutPageIntro {
          heading
          header
          subheader
          buttonLink
          buttonText
        }
        aboutPageOpeners {
          header
          blurbs {
            header
            subheader
            image {
              publicURL
            }
          }
        }
        aboutPageClosing {
          header
          subheader
          buttonLink
          buttonText
          image {
            publicURL
          }
        }
        aboutPageFounder {
          header
          image {
            publicURL
          }
          description
        }
        aboutPageContactForm {
          nameLabel
          emailLabel
          sizeLabel
          referredLabel
          messageLabel
          ctaLabel
        }
        aboutPageClients {
          title
        }
        aboutPageHashtags {
          title
        }
      }
    }
  }
`;
